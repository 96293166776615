import React from 'react';
//import { Link } from 'react-router-dom';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';
import Button from '@mui/joy/Button';
import Stack from '@mui/material/Stack';
//import Divider from '@mui/joy/Divider';
import goatvid from '../vids/Goat_BlooperEdit.mp4';

function Home() {
  return (
    <div className='Section-layout'>
      <Box sx={{ width: '100%'}}>
      <section className='Section-layout home-section'>
        <div className='article-maker-row home-tagline'>
        <Typography variant='h1' gutterBottom>
          "Helping others reach their journalistic dreams..."
        </Typography>
        </div>
        {/*<Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/donate">Donate</Link>
        <Link to="/donorrelations">Donor Relations</Link>     
  <Link to="/notfound">Not Found</Link>*/}
      {/*<div className='article-maker-row home-event-headline'>*/}
      <div className='home-event-headline'>
     {/*<Typography variant='h2'>
              Announcements
            </Typography>*/}
        </div>
        <Stack spacing={2}  direction="row" className='home-section-content'>
          {/*<div className='home-section-content-left'>
          {/*<div className='home-section-content-left'>
            <Typography variant='h4' gutterBottom>
              We recently reached our fundraising goal of $7,500!
            </Typography>
            {/*<Button component="a" color="warning" variant="solid" href="donate">Learn more</Button>
          </div>*/}
          {/*<Divider
            color='yellow'
            light 
            sx={{ 
              width: '10px',
               }}
              orientation="vertical" />*/}
          {/*<div className='home-section-content-right'>
            <Typography variant='h4' gutterBottom color={'white'}>
              <b>
                Don't miss our Extended Holiday Double Good Popcorn Fundraiser Event! <br></br>12/7/2023 (5pm EDT) to 12/11/2023 (5pm EDT)
              </b>
              </Typography>
            <Button component="a" color="warning" variant="solid" href="https://s.dgpopup.com/xo6655yd">Check it Out!</Button>
          </div>*/}        
        </Stack>
      </section>
      <section className='Section-layout donor-news'>
        <div className='article-maker-content donor-news-content'>
        <Typography variant='h1' gutterBottom>
            Thank you Donors!
          </Typography>
          <Typography variant='h5' textAlign={'justify'}>
            Because of you we were able to reach our $7,500 fundraising goal in July 2023, and the donations continue to pour in!
          </Typography>
          {/*<br></br>
          <Typography variant='h6' gutterbottom>
          <b>July 2023  Donation Blitz and DoubleGood Popcorn Event Totals</b>
          </Typography>
          <div className='bar-light-grey'>
              <div class="w3-container w3-red w3-center">Current 2023 Donations: $3,534.58</div>
          </div><br></br>
          <div className='bar-light-grey'>
              <div class="w3-container w3-orange w3-center">DoubleGood Sales: $1,947</div>
          </div>*/}<br></br>
          <Typography variant='h5' textAlign={'justify'}>
          Let's keep the momentum and support going as we continue to accept donations throughout the year, and in future fundraising events. 
          </Typography>
          <br></br>
          <Button component="a" color="warning" variant="solid" href="donate">Donate Today</Button>
          </div>
      </section>
      <section className='Section-layout news-section'>
        <div className='article-maker-content'>
          <Typography variant='h1' gutterBottom>
              Recent News
            </Typography>
            <div className='article-maker-row'>
              <div className='article-maker-column'>
                <Typography variant='h4' gutterBottom>
                The Very First TDT Scholarship Recipients!!!
                </Typography>
                <Typography variant='body1' gutterBottom>
                On Thursday, May 4, 2023 at Honors night at Taylor's alma mater Heritage High School in Conyers, Georgis we welcomed the first awardees of the Taylor D. Terrell Memorial Scholarship - Brianna Jackson and Keith Copeland Jr.
                </Typography>
              </div>
              <div className='article-maker-column-pic'>
                <img src={require('../images/TDT_scholars_year_one.jpg')} alt='firstTdtScholars'></img>
              </div>
            </div>
            <div className='article-maker-row'>
              <Typography variant='h4'>
               The Awardees
              </Typography>
              </div>
              <div className='article-maker-row'>
              <Typography variant='body1' textAlign={'justify'}>
               <b>Brianna Jackson</b> (pictured holding the 1st prized check of $2,000) will be attending North Carolina Central University to study Broacast Communications in the Fall of 2023. <b>Keith Copeland Jr.</b> (our runner up pictured with the TDT Scholarship certificate, and awarded $500) will be attending Georgia State College to major in Sports Journalism in the Fall 2023. Both are eagerly excited to start their journeys.
              </Typography>
              </div>
              <br></br>
              <div div className='article-maker-row'>
               <Typography variant='h5' textAlign={'justify'}>
               Read more on the event <a href="https://www.snntv.com/story/48863275/seven-years-in-the-making-the-first-taylor-d-terrell-scholarship-recipients">here</a>...
               </Typography>
            </div>
        </div>
      </section>
      <section className='Section-layout'>
        <div className='home-news-vid'>
        <Typography variant='h1' gutterBottom>
              One of Many Memories: Bloopers with Hazel
          </Typography>
          <video controls className='vid'>
            <source src={goatvid} type='video/mp4'/>
          </video>
        </div>
      </section>
      </Box>
    </div>
  );
}

export default Home;