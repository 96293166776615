import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/material/Stack';
import 'react-toastify/dist/ReactToastify.min.css';
import { Box } from '@mui/material';


const ContactForm = () => {
    const {
      register,
      handleSubmit,
      reset/*,
      formState: { errors }*/
    } = useForm();
    
    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
      toast('Email sent!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: 'submit-feedback success',
        toastId: 'notifyToast'
      });
    };
    
    //Environment Variables neede for EmailJS
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_USER_ID;



    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {
      // Destructure data object
      const { name, email, subject, message } = data;

      try {
        const templateParams = {
          name,
          email,
          subject,
          message
        };
  
        //need to convert env to string
        await emailjs.send(
            SERVICE_ID,
            TEMPLATE_ID,
            templateParams,
            USER_ID
        );
  
        reset();
        toastifySuccess();
      } catch (e) {
        console.log(e);
      }
    };
  
    return (
      <div className='ContactForm'>
            <Box 
                sx={{
                py: 2,
                display: 'grid',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
                }}
            >
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} direction='column'>
                  {/* Row 1 of form */}
                    <Input 
                        type='text'
                        name='name'
                        placeholder='Full Name'
                        required
                        {...register('name', {
                            required: { value: true, message: 'Please enter your name' },
                            maxLength: {
                            value: 30
                            }
                        })}
                        className='form-control formInput'/>
                  {/* Row 2 of form */}
                      <Input
                        type='email'
                        name='email'
                        placeholder='Email address'
                        required
                        {...register('email', {
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        })}
                        className='form-control formInput'
                      />
                  {/* Row 3 of form */}
                      <Input
                        type='text'
                        name='subject'
                        placeholder='Subject'
                        required
                        {...register('subject', {
                          required: { value: true, message: 'Please enter a subject' },
                          maxLength: {
                            value: 75,
                            message: 'Subject cannot exceed 75 characters'
                          }
                        })}
                        className='form-control formInput'
                      />
                  {/* Row 4 of form */}
                      <Textarea
                        minRows={3}
                        name='message'
                        placeholder='Message'
                        required
                        {...register('message', {
                          required: true
                        })}
                        className='form-control formInput'
                      />
                    <Button variant='solid' className='submit-btn' type='submit'>
                        Submit
                    </Button>
                  </Stack>
                </form>
              <ToastContainer />
        </Box>
      </div>
    );
  };


export default ContactForm;