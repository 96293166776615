import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';


function About() {
  return (
    <div className='Section-layout'>
      <Box sx={{ width: '100%'}}>
      <section className='Section-layout about-section'>
      <div className=' section-content-reg about-content'>
        <Typography variant='h1' gutterBottom>
          About
        </Typography>
        <div  className='article-maker-row'>
        <div className='article-maker-column'>
          <Typography variant='body1' gutterBottom>
              In honor of our beloved daughter, sister, niece, cousin, and friend, we
              established the Taylor D. Terrell Scholarship! This scholarship is currently awarded annually to a
              graduating senior(s) from Heritage High School (Taylor’s alma mater), who plans to major in either
              journalism, communications, marketing, or public relations, as she did.
          </Typography>
          <Typography variant='body1' gutterBottom>
              The goal of the scholarship is not just about keeping Taylor's memory and legacy alive, but also helping the next generation of those
              in the communications field reach their own career goals through academia. Our objective is to offer the
              first $2,000 scholarship and if for compelling reasons offer a secondary scholarship of $500.
            </Typography>
            </div>
          </div>
          </div>
      </section>
      </Box>
    </div>
  );
}

export default About;