import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';
import ContactForm from './ContactForm';


function Contact() {
  return (
    <div className='Section-layout'>
      <Box  sx={{ width: '100%'}}>
      <div className=' section-content-reg'>
      <Typography variant='h2' gutterBottom>        
        Contact Us
        </Typography>
        <Typography variant='subtitle1' gutterBottom>        
        Feel free to connect or chat with us on our Facebook page for the <a className='contact-form-dress' href="https://www.facebook.com/tdtscholarship">TDT Scholarship</a> 
        </Typography>
        <div className='contact-form'>
          <ContactForm/>
        </div>
      </div>
      </Box>
    </div>
  );
}

export default Contact;