import React, { useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import boardData from './data/boardInfo.json';
import boardavatar from '../images/board/avatar/standin-icon-png-01.png';
//import Stack from '@mui/material/Stack';


function TdtBoard() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulate the asynchronous loading of data (you can use actual API call or fetch here)
    setTimeout(() => {
      // Once the data is loaded, set the loaded flag to true
      setLoaded(true);
    }, 1000); // Adjust the delay according to your needs
  }, []);

  if (!loaded) {
    // Render a loading state until data is loaded
    return <div className='Section-layout tdtboard tdtboard-load'>
              <CircularProgress variant="solid" size="lg" color='info'/>
            </div>;
  }

  return (
    <div className='Section-layout tdtboard'>
      <Typography level='display2' gutterBottom>
        TDT Foundation Board Members
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{
            '--Grid-borderWidth': '1px',
            borderTop: 'var(--Grid-borderWidth) solid',
            borderLeft: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
            '& > div': {
              borderRight: 'var(--Grid-borderWidth) solid',
              borderBottom: 'var(--Grid-borderWidth) solid',
              borderColor: 'divider',
            },
          }}
        >
          {boardData.map((item, index) => (
            <Grid
              key={item.name}
              xs={12}
              sm={6}
              md={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight={180}
            >
              {/*<Avatar src={require(`${item.avatar}`)} size="lg" />*/}
              <Avatar src={boardavatar} size='lg'/>
              <Box>
                <Typography level="h2">{item.name}</Typography>
                <Typography level="body1"> Connection: {item.relation}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default TdtBoard;
