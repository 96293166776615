import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className='Section-layout'>
      <h1>Page Not Found</h1>
      <p>Error! Page Not Found</p>
      <Link to="/">Go back to Home</Link>
    </div>
  );
}

export default NotFound;