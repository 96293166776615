import React from 'react';
//import Pdf from './docs/TDT Donation Letter.pdf';
import Box from '@mui/material/Box'
import { CardContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import hhslogo from '../images/hhs-logo.png';

function DonorRelations() {
  return (
    <div className='Section-layout'>
      <Box sx={{ width: '100%'}}>
        <section className='Section-layout donor-relation-section'>
          <div className='donor-relation-section-content'>
        <Typography variant='h1' gutterBottom >
              Donor Relations
            </Typography>
          </div>
          <div className='donor-relation-section-content'>
              <Typography variant='h2' gutterBottom>
                Supporting High Schools
              </Typography>
            </div>
            <div className='donor-relation-section-content'>
            <Card sx={{ minHeight: 275 }}>
              <CardContent>
                <Typography variant='h2' gutterBottom>
                  Heritage High School
                </Typography>
                <Typography variant='subtitle1'>
                  Conyers, Georgia
                </Typography>
                <img src={hhslogo} className="App-logo" alt="hhslogo" />
              </CardContent>
            </Card>
          </div>
          <Typography variant='h3' gutterBottom> 
          Donors please<a className='contact-form-dress' href="mailto:info@tdtscholarship.org"> email us</a> or go to the <a className='contact-form-dress' href="contact">Contact Us</a> page for further inquiries.
          </Typography> 
      </section>
      </Box>
    </div>
  );
}

export default DonorRelations;