import logo from './images/main_logo.png';
import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Donate from './components/Donate';
import DonorRelations from './components/DonorRealtions';
import TdtBoard from './components/TdtBoard';
import NotFound from './components/NotFound';
import Button from '@mui/joy/Button';
import Stack from '@mui/material/Stack';
//import { styled } from '@mui/material/styles';
//import { orange } from '@mui/material/colors';
//import { createTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Typography } from '@mui/material';


/*const HeaderButtonColor = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  '&:hover': {
    backgroundColor: orange[700],
  },
}));*/

//Set Theme
/*let theme = createTheme({
  palette: {
    primary: {
      main: '#ff5349',
    },
    secondary: {
      main: '#ffa500'
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
})*/

//setSecondaryColor('#FFA500');

function App() {
  return (
    <div>
      <header className='App-header'>
        <img src={logo} onClick={() => { 
          window.location.href = '/';
        }} className="App-logo" alt="logo" />
        <Stack spacing={1} direction="row">
          <Button component="a" color="primary" variant="plain" size="md" href="/">Home</Button>
          <Button component="a" color="primary" variant="plain" size="md" href="about">About</Button>
          <Button component="a" color="primary" variant="plain" size="md" href="contact">Contact</Button>
          <Button component="a" color="primary" variant="plain" size="md" href="donate">Donate</Button>
        </Stack>
      </header>
        <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="/donate" element={<Donate/>} />
          <Route exact path="/donorrelations" element={<DonorRelations/>} />
          <Route exact path="/notfound" element={<NotFound/>} />
          <Route exact path="/tdtboard" element={<TdtBoard/>} />
        </Routes>
      </BrowserRouter>
      <footer className='footer-art'>
        <div className='footer-link-row'>
          <div className='footer-link-column'>
          <Stack spacing={1} direction='column'>
            <Typography variant='h6'><b>Links</b></Typography>
            <a className='footer-anchor-dress' href="about">About</a>
            <a className='footer-anchor-dress' href="contact">Contact</a>
            <a className='footer-anchor-dress' href="donate">Donate</a>
            <a className='footer-anchor-dress' href="donorrelations">Donor Relations</a>
            <a className='footer-anchor-dress' href="tdtboard">TDT Scholarship Board</a>
          </Stack>
          </div>
          <div className='footer-link-column'>
          <Stack spacing={1} direction='column'>
          <Typography variant='h6'><b>Social</b></Typography>
            <a className='footer-anchor-dress' href="https://www.facebook.com/tdtscholarship"><FontAwesomeIcon icon={faFacebookF} size='2x' /></a>
          </Stack>
          </div>
          <div className='footer-link-infocolumn'>
          <Stack spacing={1} direction='column'>
            <p>Disclaimer: TDT Scholarship is 501(c)(3) under the Terrell Education Foundation based Georgia, United States.</p>
            <p>Address: 2696 Club Forest Drive SE, Conyers, GA 30013</p>
            <p>Email: <a className='footer-anchor-dress' href="mailto:info@tdtscholarship.org"> info@tdtscholarship.org</a></p>
          </Stack>
          </div>
        </div>
        <div className='footer-link-row footer-copyright'>
          <p>In Loving Memory</p>
          <p>Taylor D. Terrell (July 22, 1991 - July 21, 2016)</p>
          <p>&copy; 2023 Terrell Education Foundation</p> 
        </div>
         </footer>
      </div>

  );
}

export default App;
