import React from 'react';
import { Link } from 'react-router-dom';
import Pdf from './docs/TDT Donation Letter.pdf';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import { CardContent, Typography } from '@mui/material';

function Donate() {
  return (
    <div className='Section-layout'>
      <Box sx={{ width: '100%'}}>
        <section className='Section-layout donate-section'>
      <div className='section-content-reg'>
    <Card sx={{ minHeight: 275 }}>
      <CardContent>
        <Typography variant='h2' gutterBottom>
          Ways to Donate
        </Typography>
        <div  className='article-maker-row'>
          <div className='article-maker-column'>
            <Typography variant='body1' gutterBottom>
            <ul>
              <li>Via check using our printable paper mailing <a href={Pdf} target="_blank" without rel="noopener noreferrer" >form </a> (also as a link at the bottom of the page).  Please make all checks payable to Terrell Educational Foundation</li>
              <li>Using our <a href="https://forms.gle/Rp9tGHaDTUwi4yXD6" target="_blank" without rel="noopener noreferrer" > online form</a></li>
              <li>Via CashApp: $TDTFund</li>
              <li>Via Zelle using donate@tdtscholarship.org</li>
              <li>PayPal at <a href="https://tinyurl.com/TDTPayPal" target="_blank" without rel="noopener noreferrer" > https://tinyurl.com/TDTPayPal </a></li>
              </ul> All contributions are tax-deductible (IRS EIN#: 46-3117494), and receipts will be distributed automatically for all donations of $250 or more.
            <br></br>
            <Link to="mailto:info@tdtscholarship.org">Have additional questions click here to email us.</Link>
            </Typography>
            </div>
          </div>
          </CardContent>
        </Card>
      </div>
      </section>
      </Box>
    </div>
  );
}

export default Donate;